// src/App.js
import React, { useState, useEffect } from "react";
import P5Component from "./P5Component";
import "./css/OverlayMask.css";
import dd1 from "./images/desert_days/desert_day_1.jpg";
import dd2 from "./images/desert_days/desert_day_2.jpg";
import dd3 from "./images/desert_days/desert_day_3.jpg";
import dd4 from "./images/desert_days/desert_day_4.jpg";
import dd5 from "./images/desert_days/desert_day_5.jpg";
import la1 from "./images/los_angeles/los_angeles_1.jpg";
import la2 from "./images/los_angeles/los_angeles_2.jpg";
import la3 from "./images/los_angeles/los_angeles_3.jpg";
import la4 from "./images/los_angeles/los_angeles_4.jpg";
import la5 from "./images/los_angeles/los_angeles_5.jpg";

function App() {
  const [key, setKey] = useState(0); // State to force re-render

  useEffect(() => {
    function handleResize() {
      // Update state to force re-render on resize or orientation change
      setKey((prevKey) => prevKey + 1);
    }

    window.addEventListener("resize", handleResize);
    window.addEventListener("orientationchange", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("orientationchange", handleResize);
    };
  }, []);

  const scrollToSection = (sectionId) => {
    const element = document.getElementById(sectionId);
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };
  const linkStyle = {
    textDecoration: "none",
    transition: "color 0.3s" // Smooth color transition over 0.3 seconds
  };

  const handleExternalLinkClick = (url) => {
    window.open(url, "_blank"); // Opens the URL in a new tab
  };

  const handleEmailLinkClick = (event) => {
    event.preventDefault(); // Prevent default behavior
    if (window.location.href !== "mailto:st.stoikos@gmail.com") {
      window.location.href = "mailto:st.stoikos@gmail.com"; // Navigate to email client
    }
  };

  const wideListStyle = {
    width: "80%", // Adjust the width as needed
    textAlign: "right", // Aligns the content to the right
    marginLeft: "auto", // Aligns the entire list to the right
    paddingRight: "0", // Removes right padding
    marginTop: "0", // Removes top margin
  };

  const firstWordStyle = {
    color: "white", // Style for the first word
  };

  const restOfText = {
    color: "rgb(255, 247, 198)", // Style for the rest of the text
  };

  const listItemStyle = {
    textAlign: "right", // Aligns text within each list item to the right
  };

  const blueColor = { color: "rgb(81, 133, 188)" };
  const paragraphStyleFirst = { marginBottom: "250px", textAlign: "right" };
  const paragraphStyle = { marginBottom: "320px", textAlign: "right" }; // Adding margin between paragraphs
  const paragraphStylePhotos = { marginTop: "20px", textAlign: "right" };

  return (
    <main>
      <div className="outer-overlay">
        <div className="inner-overlay"></div>
      </div>
      <div className="top-overlay">
        <ul>
          <h1>stefanos stoikos</h1>
          <h2>engineer & photography</h2>
          <li>
            <a
              href="#home"
              style={linkStyle}
              onClick={() => scrollToSection("section1")}
              onMouseEnter={(e) => (e.target.style.color = "rgb(81, 133, 188)")} // Changes color on hover
              onMouseLeave={(e) => (e.target.style.color = "whitesmoke")}
            >
              home
            </a>
          </li>
          <li>
            <a
              href="#work"
              style={linkStyle}
              onClick={() => scrollToSection("section2")}
              onMouseEnter={(e) => (e.target.style.color = "rgb(81, 133, 188)")} // Changes color on hover
              onMouseLeave={(e) => (e.target.style.color = "whitesmoke")}
            >
              work
            </a>
          </li>
          <li>
            <a
              href="#photography"
              style={linkStyle}
              onClick={() => scrollToSection("section3")}
              onMouseEnter={(e) => (e.target.style.color = "rgb(81, 133, 188)")} // Changes color on hover
              onMouseLeave={(e) => (e.target.style.color = "whitesmoke")}
            >
              photography
            </a>
          </li>
          <li>
            <a
              href="#about"
              style={linkStyle}
              onClick={() => scrollToSection("section4")}
              onMouseEnter={(e) => (e.target.style.color = "rgb(81, 133, 188)")} // Changes color on hover
              onMouseLeave={(e) => (e.target.style.color = "whitesmoke")}
            >
              about
            </a>
          </li>
        </ul>
        <div className="container">
          <div className="scrollable-content">
            <section id="section0" style={{ textAlign: "right" }}>
              <p style={paragraphStyleFirst}></p>
            </section>
            <section
              id="section1"
              style={{ textAlign: "right", width: "50%", marginLeft: "auto" }}
            >
              <h2 style={blueColor}>~ ~ ~</h2>
              <p style={paragraphStyle}>
                software engineer from greece, currently based in los angeles.
                studied computer science at pomona college. passionate about
                research in llms, recommender systems and music. filming with my
                drone and shooting film in my free time.
              </p>
            </section>
            <section id="section2" style={{ textAlign: "right" }}>
              <h2 style={blueColor}>work</h2>
              <p style={paragraphStyle}>
                <ul style={wideListStyle}>
                  <li style={listItemStyle}>
                    <span style={firstWordStyle}>
                      NORDSTROM, Los Angeles, CA
                    </span>
                  </li>
                  <li style={listItemStyle}>
                    <span style={restOfText}>
                      data engineer, 2023-current
                    </span>
                  </li>
                  <li style={listItemStyle}>
                    <span style={firstWordStyle}>-</span>
                  </li>
                  <li style={listItemStyle}>
                    <span style={firstWordStyle}>
                      POMONA COLLEGE, Claremont, CA
                    </span>
                  </li>
                  <li style={listItemStyle}>
                    <span style={restOfText}>
                      computer science research assistant, 2022
                    </span>
                  </li>
                  <li style={listItemStyle}>
                    <span style={firstWordStyle}>-</span>
                  </li>
                  <li style={listItemStyle}>
                    <span style={firstWordStyle}>
                      FERRYHOPPER, Athens, Greece
                    </span>
                  </li>
                  <li style={listItemStyle}>
                    <span style={restOfText}>
                      data engineer, 2021
                    </span>
                  </li>
                  <li style={listItemStyle}>
                    <span style={firstWordStyle}>-</span>
                  </li>
                  <li style={listItemStyle}>
                    <span style={firstWordStyle}>
                      CLAREMONT MCKENNA COLLEGE, Claremont, CA
                    </span>
                  </li>
                  <li style={listItemStyle}>
                    <span style={restOfText}>
                      mathematics research assistant, 2020
                    </span>
                  </li>
                </ul>
                <h2 style={blueColor}>publications</h2>
                <ul style={wideListStyle}>
                  <li style={listItemStyle}>
                    <span style={firstWordStyle}>
                      <a
                        href="https://dl.acm.org/doi/abs/10.1145/3591106.3592274"
                        style={linkStyle}
                        onClick={() =>
                          handleExternalLinkClick(
                            "https://dl.acm.org/doi/abs/10.1145/3591106.3592274"
                          )
                        }
                        onMouseEnter={(e) =>
                          (e.target.style.color = "rgb(81, 133, 188)")
                        } // Changes color on hover
                        onMouseLeave={(e) =>
                          (e.target.style.color = "whitesmoke")
                        }
                        target="_blank" // Opens the link in a new tab
                        rel="noopener noreferrer" // Recommended for security reasons
                      >
                        CROSS-LANGUAGE MUSIC RECOMMENDATION EXPLORATION
                      </a>
                    </span>
                  </li>
                  <li style={listItemStyle}>
                    <span style={restOfText}>
                      acm icmr - thessaloniki, greece, jun 12-15, 2023
                    </span>
                  </li>
                  <li style={listItemStyle}>
                    <span style={firstWordStyle}>-</span>
                  </li>
                  <li style={listItemStyle}>
                    <span style={firstWordStyle}>
                      <a
                        href="https://aclanthology.org/2020.peoples-1.11/"
                        style={linkStyle}
                        onClick={() =>
                          handleExternalLinkClick(
                            "https://aclanthology.org/2020.peoples-1.11/"
                          )
                        }
                        onMouseEnter={(e) =>
                          (e.target.style.color = "rgb(81, 133, 188)")
                        } // Changes color on hover
                        onMouseLeave={(e) =>
                          (e.target.style.color = "whitesmoke")
                        }
                        target="_blank" // Opens the link in a new tab
                        rel="noopener noreferrer" // Recommended for security reasons
                      >
                        {" "}
                        MULTILINGUAL EMOTICON PREDICTION OF TWEETS ABOUT
                        COVID-19{" "}
                      </a>
                    </span>
                  </li>
                  <li style={listItemStyle}>
                    <span style={restOfText}>
                      acl coling - barcelona, spain, dec 8-13, 2020
                    </span>
                  </li>
                </ul>
              </p>
            </section>
            <section id="section3" style={{ textAlign: "right" }}>
              <h2 style={blueColor}>photography</h2>
              <p style={paragraphStylePhotos}>
                los angeles. 35mm minolta x-700.
              </p>
              <div class="image-grid">
                <div class="grid-item">
                  <img src={la1} alt=""></img>
                </div>
                <div class="grid-item">
                  <img src={la2} alt=""></img>
                </div>
                <div class="grid-item">
                  <img src={la4} alt=""></img>
                </div>
                <div class="grid-item">
                  <img src={la3} alt=""></img>
                </div>
                <div class="grid-item">
                  <img src={la5} alt=""></img>
                </div>
              </div>
              <p style={paragraphStylePhotos}>
                desert days. 35mm minolta x-700.
              </p>
              <div class="image-grid">
                <div class="grid-item">
                  <img src={dd4} alt=""></img>
                </div>
                <div class="grid-item">
                  <img src={dd1} alt=""></img>
                </div>
                <div class="grid-item">
                  <img src={dd2} alt=""></img>
                </div>
                <div class="grid-item">
                  <img src={dd3} alt=""></img>
                </div>
                <div class="grid-item">
                  <img src={dd5} alt=""></img>
                </div>
              </div>
              <p style={paragraphStylePhotos}>daydream. 4k dj mini 2.</p>
              <div class="video-container">
                <iframe
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/vN6wxrKgPXs?si=TkoNZAcf1TZGJGfX"
                  frameborder="0"
                  allowfullscreen
                ></iframe>
              </div>
              <p style={paragraphStyle}></p>
            </section>
            <section id="section4" style={{ textAlign: "right" }}>
              <h2 style={blueColor}>about</h2>
              <p style={paragraphStyle}>
                <ul style={wideListStyle}>
                  <li style={listItemStyle}>
                    <a
                      href="mailto:st.stoikos@gmail.com"
                      style={linkStyle}
                      onMouseEnter={(e) => (e.target.style.color = "yellow")} // Changes color on hover
                      onMouseLeave={(e) =>
                        (e.target.style.color = "whitesmoke")
                      } // Restores color when not hovered
                      onClick={handleEmailLinkClick}
                    >
                      email 📧
                    </a>
                  </li>

                  <li style={listItemStyle}>
                    <a
                      href="https://www.linkedin.com/in/stefanos-stoikos"
                      style={linkStyle}
                      onMouseEnter={(e) => (e.target.style.color = "yellow")} // Changes color on hover
                      onMouseLeave={(e) =>
                        (e.target.style.color = "whitesmoke")
                      } // Restores color when not hovered
                      onClick={() =>
                        handleExternalLinkClick(
                          "https://www.linkedin.com/in/stefanos-stoikos"
                        )
                      } // Opens link in new tab on click
                      target="_blank" // Opens the link in a new tab
                      rel="noopener noreferrer" // Recommended for security reasons
                    >
                      linkedin ↯
                    </a>
                  </li>
                  <li style={listItemStyle}>
                    <a
                      href="https://scholar.google.com/citations?user=FLR5YEgAAAAJ&hl=en"
                      style={linkStyle}
                      onMouseEnter={(e) => (e.target.style.color = "yellow")} // Changes color on hover
                      onMouseLeave={(e) =>
                        (e.target.style.color = "whitesmoke")
                      } // Restores color when not hovered
                      onClick={() =>
                        handleExternalLinkClick(
                          "https://scholar.google.com/citations?user=FLR5YEgAAAAJ&hl=en"
                        )
                      } // Opens link in new tab on click
                      target="_blank" // Opens the link in a new tab
                      rel="noopener noreferrer" // Recommended for security reasons
                    >
                      google scholar ↯
                    </a>
                  </li>
                  <li style={listItemStyle}>
                    <a
                      href="https://github.com/Stefanos-stk"
                      style={linkStyle}
                      onMouseEnter={(e) => (e.target.style.color = "yellow")} // Changes color on hover
                      onMouseLeave={(e) =>
                        (e.target.style.color = "whitesmoke")
                      } // Restores color when not hovered
                      onClick={() =>
                        handleExternalLinkClick(
                          "https://github.com/Stefanos-stk"
                        )
                      } // Opens link in new tab on click
                      target="_blank" // Opens the link in a new tab
                      rel="noopener noreferrer" // Recommended for security reasons
                    >
                      github ↯
                    </a>
                  </li>
                </ul>
              </p>
            </section>
          </div>
        </div>
      </div>
      <P5Component key={key} />
    </main>
  );
}

export default App;
