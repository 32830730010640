var scl = 15;
var cols, rows;
var width = window.innerWidth; // Width of the viewport
var height = window.innerHeight; // Height of the viewport
cols = Math.floor(width/ scl);
rows = Math.floor(height / scl);
var partStroke = 0.4; // line width

export class Particle {

    constructor(p) {
      this.pos = p.createVector(Math.random(window.screen.width), Math.random(window.screen.height));
      this.vel = p.createVector(0, 0);
      this.acc = p.createVector(0, 0);
      this.maxspeed = 4;
      this.prevPos = this.pos.copy();
    }
  
    update() {
      this.vel.add(this.acc);
      this.vel.limit(this.maxspeed);
      this.pos.add(this.vel);
      this.acc.mult(0);
    }
  
    follow(vectors) {
      var x = Math.floor(this.pos.x / scl);
      var y = Math.floor(this.pos.y / scl);
      var index = x + y * cols;
      var force = vectors[index];
      this.applyForce(force);
    }
  
    applyForce(force) {
      this.acc.add(force);
    }
  
    show(p) {
      p.stroke(40, 40, 40, 90); // Even darker gray stroke color with transparency
      p.strokeWeight(partStroke);
      p.line(this.pos.x, this.pos.y, this.prevPos.x, this.prevPos.y);
      this.updatePrev();
    }
  
    updatePrev() {
      this.prevPos.x = this.pos.x;
      this.prevPos.y = this.pos.y;
    }
  
    edges() {
      if (this.pos.x > window.innerWidth) {
        this.pos.x = 0;
        this.updatePrev();
      }
      if (this.pos.x < 0) {
        this.pos.x =window.innerWidth;
        this.updatePrev();
      }
      if (this.pos.y > window.innerHeight) {
        this.pos.y = 0;
        this.updatePrev();
      }
      if (this.pos.y < 0) {
        this.pos.y = window.innerHeight;
        this.updatePrev();
      }
  
    }
  
  }