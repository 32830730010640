// src/P5Component.js
import React, { useEffect } from 'react';
import p5 from 'p5';
import {Particle} from './Particle.js';

// chaotic angMult 10 angTurn 1 scl 5 numbPart 500


var angMult = 10; // 0.1 = straighter lines; 25+ = sharp curves
var angTurn = 1; // adjust angle for straight lines (after adjusting angMult)
var scl = 5;
var cols,rows;
var flowfield;
var particles = [];
var numbPart = 500; 
var zoff = 0;
var zOffInc = 0.0003;
var inc = 0.1;
let startTime;


const P5Component = () => {
  var width = window.innerWidth; // Width of the viewport
  var height = window.innerHeight; // Height of the viewport
  useEffect(() => {
    const sketch = (p) => {
      p.setup = () => {
        let canvas;
        canvas = p.createCanvas(width,height);
        p.colorMode(p5.HSB,360,100,100,100);
        canvas.position(0,0);
        canvas.style('display','block')
        p.background(0);
        cols = Math.floor(width / scl);
        rows = Math.floor(height / scl);     
        
        startTime = p.millis()

        flowfield = new Array(cols * rows);
        for (var i = 0; i < numbPart; i++) {
          particles[i] = new Particle(p);
        }
      };


      p.draw = () => {
        let delta = (p.millis() - startTime) / 1000
        if (delta < 90){
          var yoff = 0;
          for (var y = 0; y < rows; y++) {
            var xoff = 0;
            for (var x = 0; x < cols; x++) {
              var index = x + y * cols;
              var angle = p.noise(xoff, yoff, zoff)*angMult+angTurn;  
              var v = p5.Vector.fromAngle(angle);
              v.setMag(1);
              flowfield[index] = v;
              xoff += inc;
              // p.stroke(300, 10);
              // p.push();
              // p.translate(x * scl, y * scl);
              // p.rotate(v.heading());
              // p.strokeWeight(3);
              // p.point(scl, 0);
              // p.pop();
            }
            
            yoff += inc;
            zoff += zOffInc;
          }
  
          for (var i = 0; i < particles.length; i++) {
            particles[i].follow(flowfield);
            particles[i].update();
            particles[i].edges();
            particles[i].show(p);
          }        
        }

        
      };
    };

    const canvas = new p5(sketch);

    // Clean up the p5 instance when the component unmounts
    return () => {
      canvas.remove();
    };
  }, []); // Empty dependency array ensures useEffect runs once on mount

  return <div id="p5-container"></div>;
};

export default P5Component;
